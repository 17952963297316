@import "../AppVariables.scss";
.dropdown-item.active, .dropdown-item:active{
    background-color: $grey;
    
    color: $white !important;
}
dropdown.show button{
    color: $white;
}
#currentUser{
    // background: rgba(255,255,255,0.2);
    // padding: 0.5em;
    // min-width: 6em;
    min-height: 1em;
    
   
    text-align: right;
    
    
    .userInfo{
        padding-right: 0;
        display: table-cell;
        vertical-align: middle;
        line-height: normal;
        p{
            // margin-top: 2%;
            // margin-bottom: auto;
        };
    }
    #userName{
        height: 25px;
        display: inline-block;
        vertical-align: middle;
        line-height: 2em;
        // margin-bottom: 12px;
    }
    #userImg{
        width: 25px;
        height: 25px;
        display: inline-block;
        // background: #fff;
        background: rgba(0,0,0,0.2);
        
        border-radius: 20px;
        border: 1px solid #000;
        img{
            width: 20px;
            height: 20px;
        }
    }
    hr{
        margin: 0;
        border-top: 1px solid rgba(255,255,255,0.5);
    }
    p{
        margin: 0;
        padding: 0 0 0 .2em;
        color: #000;
        text-align: right;
        font-size: 0.9em;
    }
    .inline{
        display: inline !important;
    }
}

