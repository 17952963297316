@import "../AppVariables.scss";
.content{
    margin-top: -14.2em;
    z-index: 2;
}
.layout_container{
    height: 100%;
   
    overflow-x: hidden;
}
