@import '../AppVariables.scss';
.container{
   margin-top: 0.5rem;
   cursor: pointer;
   width: auto;
   height: 6rem;
   background-color: $light-grey;
    border: 0.001rem  $cloud-grey;
    border-radius: 1rem;
   
}
.rowContainer{
  display: flex;
  justify-content: space-between;
  

}

.timeStamp{
color: gray;
padding: 0.5rem;
font-size: 0.8rem;
flex: 0.5;
display: flex;
}
  .message{
    // font-weight: 600;
    padding: 0.5rem;
    font-size: 0.8rem;
    flex: 1.6;
display: flex;
  }
  
  
