$baseColor: #000;
$light-blue: #f9f8fe;
// $baseColor: #163d77;
$white: #ffffff;
$base-grey: #e6e6e6;
$green: #19bb9b;
$grey: #424242;
$dark-grey: #424242;
$darker-grey: #676767;
$baseColor-grey: #70859e;
$black: #000;
$yellow: #eeac11;
$light-yellow: #fff5e1;
$blue-grey: #99a6ab;
$green: #1abb9c;
$light-grey: #e9ecef;
$cloud-grey: #c5c6d0;

// breakpoints
$x3-large-screen: 2048px;
$x2-large-screen: 1920px;
$x-large-screen: 1200px;
$large-screen: 992px;
$medium-screen: 768px;
$small-screen: 540px;
