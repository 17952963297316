@import '../AppVariables.scss';

.header{
   
    padding: 0;
}
.mainApp{
    // background-color: $yellow;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
   
    
   

     @media (min-width: $medium-screen) {
        min-height: 4rem;
       
        
      }
    .btn-secondary{
        // background-color: transparent;
        border: none;
        margin-left: 0.5em;
        :hover, :active{
            // background-color: rgba(255,255,255,0.2) !important;
        }
    }
    .dropdown.show{
        button{
            // background: #fff;
            color: $baseColor;
        }
        
        p{
            color: $baseColor !important;
        };
        #userImg{
            border-color: $baseColor !important;
        }
    }
    .navName{
        // padding-top: 3em;
        line-height: 1em;
        vertical-align: middle;
        b{
            // padding-top: 1em;
            
            // padding: 0;
            // padding-top: 1em;
        }
        // line-height: 2em;
        // margin-top: 1em;
    }
    .largeDropdown{
        width: 300px;
        overflow: scroll;
        padding: 5px;
        .MainNavAppContainer{
            .appBox{
            text-align: center;
            transition: padding 0.2s;
            .col{
                // background: $grey;
                margin: 0;
                padding: 0
            }
            svg{
                margin-top: 0.5em !important;
            // padding-bottom: 1em;
            }
        }
        .appBox:hover{
            // text-align: center;
            padding: 0 !important;
            .col, p{
                background: $baseColor;
                color: $white !important;
            }
            
        }
    }}


    /* Main styles for the navbar */
.navbar-nav {
    margin-left: auto; /* Align to the right */
   // margin-top: 2em;
    padding: 0; /* Remove default padding */
    list-style: none; /* Remove list-style bullets */
    display: flex; /* Display items horizontally */
    align-items: center; /* Vertically center items */
    justify-content: space-between;
   
    width: 100%;
    @media (min-width:$large-screen) {
        justify-content: center;
        width: auto;
    }

    .current_partner{
        margin-top: 1rem;
        color: $baseColor;
        font-weight: bold;
        font-size: 0.69rem;
        width: 8.5rem;
       
        @media (min-width:$large-screen) {
            font-size: 1rem;
           width: 15rem;
        }
      }

      .mobileMenuIcon{
        display: flex;
        margin-right: 2rem;
        @media (min-width:$large-screen) {
           display: none;
        }
      }
      
}
  
  
 
  

}

.sidebarBtn{
    // color: $white;
    // font-size: 25px;
    // font-weight: bold;
    // background-color: $white;
    line-height: 1;
    padding: .5em;
    margin-left: 1em;
    
}
.sidebarBtn:hover{
    // background-color:$white;
    // padding: .5em;
    border: 1px solid $white;
}